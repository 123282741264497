
// Libraries
import * as React from 'react'

// Services
import UserService from "../../../services/user"

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import DuoSection from '../../../components/duoSection'

// Context
import NotificationContext from '../../../contexts/notification'

class RegisterCarerSuccessPage extends React.Component {
	static contextType = NotificationContext

	render() {
		return <Layout className="page--success nav-blue-half no-footer carer-success">
			<Seo title="Register Carer Success" />
			<DuoSection success carer-success RegCarerSuccess>
				<div>
					<div className="content-wrap">
						<h1>Success!</h1>
						<p>
							Welcome to Concerts for Carers.<br/>
							To complete your registration please follow the link in the email that we've sent you.
							Once you have completed your registration you can view the events and enter ballots by
							clicking the “Events” link at the top of the page.
						</p>
						<button onClick={this.handleResendLink} className="link--underline">Resend link</button>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}

	handleResendLink = async (clickEvent) => {
		clickEvent.preventDefault()

		const {addNotification} = this.context

		const {token} = this.props
		const userService = new UserService()
		await userService.resendVerificationLink(token)
			.then((response) => {
				if (response.success) {
					addNotification('Email resent, please check your inbox', 'success')
				} else {
					addNotification('Invalid request', 'error')
				}
			})
			.catch(err => console.error(err))

	}
}

export default RegisterCarerSuccessPage
